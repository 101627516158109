import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { Layout } from 'modules/ui';
import { BlogPostDetailPage, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostDetail as BlogPostDetailData } from 'modules/blog/types';

interface DataProps {
    strapiPost: BlogPostDetailData;
    allStrapiPost: {
        nodes: BlogPost[];
    };
}

const BlogPostDetail = ({ data }: PageProps<DataProps>) => {
    const post = data.strapiPost;

    const metaImage = post?.seo?.metaImage ?? post?.thumbnail?.formats.large ?? post?.thumbnail;
    const metaImageSize = metaImage ? { width: metaImage.width, height: metaImage.height } : undefined;

    return (
        <>
            <BlogSeo
                title={post?.seo?.metaTitle ?? post?.title}
                description={post?.seo?.metaDescription || post?.content.data.childMdx.excerpt}
                keywords={post?.seo?.keywords}
                titleTemplate="%s"
                image={metaImage?.url}
                imageSize={metaImageSize}
                meta={[
                    {
                        property: 'article:published_time',
                        content: post?.publishedAt,
                    },
                    {
                        property: 'article:modified_time',
                        content: post?.updatedAt,
                    },
                ]}
                ogType="article"
            />
            <Layout>
                <BlogPostDetailPage {...post} otherPosts={data.allStrapiPost.nodes} />
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query ($slug: String!, $localeRegex: String) {
        strapiPost(slug: { eq: $slug }, locale: { regex: $localeRegex }) {
            ...BlogPostDetail
        }
        allStrapiPost(
            sort: { fields: publishedAt, order: DESC }
            limit: 3
            filter: { slug: { ne: $slug }, locale: { regex: $localeRegex } }
        ) {
            nodes {
                ...BlogPost
            }
        }
    }
`;

export default BlogPostDetail;
